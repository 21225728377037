import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {DmsDocumentExportTransaction} from './dms-document-export';
import {DmsDocumentExportTransactionBuilder} from './dms-document-export.builder';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {ExportType, IDmsDocumentExportParams} from './dms-document-export.interface';

@Injectable()
export class DmsDocumentExportService {
  readonly BASE_PATH = '/api/v1/workflow_engine/processes';

  constructor(private _http: FivefApiResourceService,
              private _httpClient: HttpClient,
              private _domSanitizer: DomSanitizer) {
  }

  create(processId: string, exportType: ExportType, ids: string[] = null, params: IDmsDocumentExportParams): Observable<DmsDocumentExportTransaction> {
    const builder = new DmsDocumentExportTransactionBuilder();
    const _params = {
      data: {
        attributes: {
          export_type: exportType,
          dms_document_ids: ids,
          custom_file_prefix: params.custom_file_prefix,
          custom_zip_name: params.custom_zip_name,
          include_index_html: params.include_index_html,
          include_xml: params.include_xml,
          collector_item_prefix: params.collector_item_prefix,
          exclude_collector_templates: params.exclude_collector_templates,
          include_audit_trail: params.include_audit_trail,
          mark_file_exported: params.mark_file_exported,
          skip_exported_files: params.skip_exported_files
        }
      }
    };
    return <Observable<DmsDocumentExportTransaction>>this._http.post<DmsDocumentExportTransactionBuilder, DmsDocumentExportTransaction>(builder, `${this.BASE_PATH}/${processId}/file_exports`, _params);
  }

  // getAll(folderId: string = null, accountType: DmsAccountType = DmsAccountType.Private): Observable<DmsDocument[]> {
  //   const accountParam = accountType === DmsAccountType.Private ? 'private' : 'organization';
  //   const param = `?account_type=${accountParam}` + (folderId ? `&folder_id=${folderId}` : '');
  //   const builder = new DmsDocumentExportBuilder();
  //   return <Observable<DmsDocument[]>>this._http.get<DmsDocumentExportBuilder, DmsDocument>(builder, `${this.BASE_PATH}${param}`);
  // }
  //
  // getOne(id: string): Observable<DmsDocument> {
  //   const builder = new DmsDocumentExportBuilder();
  //   return <Observable<DmsDocument>>this._http.get<DmsDocumentExportBuilder, DmsDocument>(builder, `${this.BASE_PATH}/${id}`);
  // }
  //
  // remove(processId: string, id: string): Observable<DmsDocument> {
  //   const builder = new DmsDocumentExportBuilder();
  //   return <Observable<DmsDocument>>this._http.del<DmsDocumentExportBuilder, DmsDocument>(builder, `api/v1/workflow_engine/processes/${processId}/dms_documents/${id}`);
  // }
}
